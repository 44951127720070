.mat-pending,
.mat-Pending,
.mat-Required {
    color: rgb(160, 160, 160) !important;
}

.mat-warning,
.mat-active,
.mat-Active,
.mat-Partial,
.mat-PartiallyIngested,
.mat-PartiallyShot,
.mat-Accepted,
.mat-Okay {
    color: $warning !important;
}

.mat-success,
.mat-Complete,
.mat-Ingested,
.mat-Applied,
.mat-SettingsApplied,
.mat-Received,
.mat-Shot,
.mat-Approved,
.mat-Good {
    color: $success !important;
}

.mat-error,
.mat-Rejected,
.mat-Poor,
.mat-Error {
    color: $error !important;
}

.mat-info,
.mat-Info {
    color: $info !important;
}

.mat-white {
    color: white !important;
}


.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  }