/*
  THEMES
*/
@use '@angular/material' as mat;
@include mat.core();

$my-primary: mat.define-palette(mat.$gray-palette, 800);
$my-accent: mat.define-palette(mat.$gray-palette, 100, 200, 300);
$my-warn: mat.define-palette(mat.$amber-palette, 400);
// CUSTOM
$my-error: mat.define-palette(mat.$red-palette, 400);
$my-success: mat.define-palette(mat.$green-palette, 500);
$my-info: mat.define-palette(mat.$blue-palette, 300);

$my-app-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
        warn: $my-error,
      ),
    ));
@include mat.all-component-themes($my-app-theme);


$primary: mat.get-color-from-palette($my-primary, 800);
$accent :mat.get-color-from-palette($my-accent, 300);
$accent-light: mat.get-color-from-palette($my-accent, 200);
$accent-lighten: mat.get-color-from-palette($my-accent, 100);
$warning: mat.get-color-from-palette($my-warn, 400);

$error: mat.get-color-from-palette($my-error, 400);
$success: mat.get-color-from-palette($my-success, 500);
$info: mat.get-color-from-palette($my-info, 300);

/*
  SCSS files
*/
@import "./custom_buttons";
@import "./custom_icons";
@import "./custom_snackbars";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.title {
  font-size: 55px;
  line-height: 60px;
  font-weight: 600;
  color: $primary;
}

.spacer {
  flex: 1 1 auto;
}

.content {
  //min-width: 1200px;
  color: $primary;
  background-color: $accent-light;
  border-radius: 20px;
  padding: 30px;

  .sub-content {
    color: $primary;
    background-color: $accent-lighten;
    border-radius: 20px;
    padding: 15px;
  }

  .content-header {
    display: flex;
    align-items: center;
    min-height: 78px;

    form {
      width: 20%
    }

    mat-form-field {
      width: 100%;
    }
  }

  .content-actions {
    align-items: center;
    display: flex;
    margin-top: 10px;

    .action-button,
    .action-form {
      padding: 10px;
    }
  }
}

.review-actions {
  &__menu {
    max-width: 300px !important;

    button {
      width: 100%;
      min-width: 150px;
    }

    .mat-mdc-menu-content {
      display: flex;
      flex-direction: column;
      padding: 8px;
      gap: 8px;
      width: 284px;

      button[disabled] {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }
}

.cdk-overlay-pane {
  div.mat-mdc-menu-panel {
    max-width: none;
  }
}

.loading-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.remove-mat-expansion-panel-padding .mat-expansion-panel-body {
  padding: 0 !important;
}