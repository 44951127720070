
.mat-mdc-snack-bar-container {
    &.success {
      --mdc-snackbar-container-color: #D8EED2;
      --mat-mdc-snack-bar-button-color: #51a43c;
      --mdc-snackbar-supporting-text-color: #51a43c;
    }
    &.warning {
      --mdc-snackbar-container-color: #ffca28;
      --mat-mdc-snack-bar-button-color: #424242;
      --mdc-snackbar-supporting-text-color: #424242;
    }
  }
  
.mat-mdc-snack-bar-container {
    &.error {
      --mdc-snackbar-container-color: #FAE6E6;
      --mat-mdc-snack-bar-button-color: #dc5050;
      --mdc-snackbar-supporting-text-color: #dc5050;
    }
  }